// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import img2 from "./assets/Images/logo512.png";
import Card from "react-bootstrap/Card";
import Table from 'react-bootstrap/Table';

function Contact() {
  const [state, handleSubmit] = useForm("xbjnydjn");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <Container fluid className='bg-primary fluid'>
      <Row>


      <Col fluid className="m-20 py-5">
      
          <form 
            onSubmit={handleSubmit}
         
            className="fluid"
          >
            <label htmlFor="email" className="text-white h3">
              Email Address
            </label>
            <input className="m-2 py-2 w-100 bg-secondary text-white font-bold" id="email" type="email" name="email" />
            <label htmlFor="Name" className="text-white h3">Name</label>
            <input className="m-2 py-2  w-100 bg-secondary text-white font-bold" id="email" type="text" name="Name" />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />

            <label htmlFor="Mobile" className="text-white h3">Mobile No</label>
            <input className="m-2 py-2 w-100 bg-secondary text-white font-bold" id="Mobile" type="text" name="Mobile" />
            <label htmlFor="Address" className="m-1 py-1 text-white h3 ">
              Address
            </label>
            <textarea className="h-25 w-100 bg-secondary text-white font-bold" id="message" name="message" />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />

            <button
              type="submit"
              disabled={state.submitting}
              className="h3 m-4"
            >
              Submit
            </button>
            <label htmlFor="" className="text-white font-size-large m-2 h3">
              Contact for Support
            </label>

          </form>
        </Col>

        
      </Row>

      
    </Container>
  );
}

export default Contact;

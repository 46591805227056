import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "./assets/Images/Banner1.jpg";
import Image1 from "./assets/Images/ser.jpg";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import img1 from "./assets/Images/clock.jpg";
import img2 from "./assets/Images/circle1.jpg";
import img3 from "./assets/Images/footer.jpg";

import Contact from "./Contact";
const Home = () => {
  return (
    <Container className="m-10 py-5">
      <Row>
        <Col>
          <img src={Image} className="img-fluid m-10 align-center py-3 m-l" />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          {" "}
          <img src={Image1} className="img-fluid m-10 py-3" />
        </Col>

        <Col className="m-3">
          <h4 classname="">
            Welcome to DigInda Computers, your trusted partner for all your
            IT sales and service needs. With years of experience and expertise
            in the industry, we provide innovative technology solutions that
            cater to the unique needs of businesses like yours. Our mission is
            to empower your business with the latest technology advancements,
            ensuring you stay ahead of the competition.
          </h4>
        </Col>
      </Row>

      <Row className="m-3 py-1">
       
        <Row>
          <Contact />

        </Row>

        <Row>
        <Col>
          <img src={img3} className="img-fluid m-10 align-center py-3 m-l rounded-rigth" />
        </Col>
      </Row>
      </Row>
    </Container>
  );
};

export default Home;

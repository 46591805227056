import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import img1 from "./assets/Images/logo1.jpg";

function Navbartop() {
  return (
    <>

<Navbar collapseOnSelect expand="lg" className="bg-primary fixed-top">
      <Container>

      <img style={{ width: '3rem', margin:3 }} src={img1} className="img-fluid m-10 align-center py-3 m-l w-10" />
        <Navbar.Brand className='text-white text-lg' href="/">DigIndia
        
     

        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            
          <Nav.Link className='text-white text-lg-start 'href="Header">Header</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="/">Sale Offer</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="About"></Nav.Link>
           
           {/* <Nav.Link className='text-white text-lg-start'href="#features">Scanner</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="#features">Mobile</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="#features">Laptop Spare</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="#features">Desktop Spare</Nav.Link>
            <Nav.Link className='text-white text-lg-start'href="#features">Printer Spare</Nav.Link> */}
           
          </Nav>
          <Nav>
            <Nav.Link className='text-white text-lgstart' href="#deets">Contact No 9321041901</Nav.Link>
            <Nav.Link className='text-white text-lgstart' eventKey={2} href="#memes">
               DigIndia4@gmail.com
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
    </>
  );
}



  export default Navbartop;